
.dataAnalysis-container {
    position: relative;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    .dataAnalysis-wrapper {
        display: flex;
        height: 1%;
        flex: 1;
        flex-direction: column;
        background: #fff;
        margin-top: 20px;
        border-radius: 10px;
        padding: 30px 40px 0;
        box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
        .tab-wrapper {
            .tab-item {
                display: inline-block;
                height: 32px;
                padding: 0 22px;
                line-height: 32px;
                background: #F1F0FE;
                color: #584EEE;
                font-size: 16px;
                margin-right: 20px;
                border-radius: 4px;
                transition: all .3s;
                &:last-child {
                    margin-right: 0;
                }
                &.current, &:hover {
                    color: #fff;
                    background: #574DED;
                }
            }
        }
        .batch-wrapper {
            margin-top: 30px;
            display: flex;
            .batch-btn {
                cursor: pointer;
                transition: all .3s;
                i {
                    margin-right: 6px;
                }
                &:hover {
                    color: #5864e4;
                }
            }
            .choose-number {
                margin-left: 20px;
            }
        }
    }
}
.el-link + .el-link {
    margin-left: 10px;
}
